  import serv1 from '../assets/img/serv1.png';
  import serv2 from '../assets/img/serv2.png';
  import serv3 from '../assets/img/serv3.png';
  import serv4 from '../assets/img/serv4.png';
  import serv5 from '../assets/img/serv5.png';
  import serv8 from '../assets/img/serv8.png';

  const serviceData = [
    {
      icon: serv1,
      titleKey: 'solution_entreprise_title', // Use the key for the title
      status: 1,
      descriptionKey: 'solution_entreprise', // Reference translation key
      delay: 100
    },
    {
      icon: serv5,
      titleKey: 'automatisation_title', // Use the key for the title
      status: 5,
      descriptionKey: 'automatisation', // Reference translation key
      delay: 500
    },
    {
      icon: serv3,
      titleKey: 'creation_website_title', // Use the key for the title
      status: 3,
      descriptionKey: 'creation_website', // Reference translation key
      delay: 300
    },
    {
      icon: serv2,
      titleKey: 'application_mobile_title', // Use the key for the title
      status: 2,
      descriptionKey: 'application_mobile', // Reference translation key
      delay: 200
    },
    {
      icon: serv4,
      titleKey: 'renovation_website_title', // Use the key for the title
      status: 6,
      descriptionKey: 'renovation_website', // Reference translation key
      delay: 400
    },
    {
      icon: serv8,
      titleKey: 'ecommerce_title', // Use the key for the title
      status: 7,
      descriptionKey: 'ecommerce', // Reference translation key
      delay: 800
    }
  ];

  export default serviceData;
