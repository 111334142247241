import './Hero.css';
import Logo from '../../../assets/img/Logo.png';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const Hero = () => {
  const { t, i18n } = useTranslation(); // Get i18n instance to access the current language

  const socialLinks = [
    {
      icon: 'bi bi-instagram',
      link: 'https://www.instagram.com/foundrybyte',
      color: '#E4405F'
    },
    {
      icon: 'bi bi-whatsapp',
      link: 'https://wa.me/212694647806?text=Hello%20there!',
      color: '#0077B5'
    },
    {
      icon: 'bi bi-google',
      link: 'https://mail.google.com/mail/?view=cm&fs=1&to=foundrybyte@gmail.com&su=Hello',
      color: '#000000'
    },
  ];

  return (
    <section id="hero" className="hero section" dir={i18n.language === 'ar' ? 'rtl' : 'ltr'}>
      <div className="container">
        <div className="row gy-4 justify-content-center">
          <div className="col-1 social-sidebar position-fixed start-0 top-50 translate-middle-y d-none d-lg-block">
            <div className="d-flex flex-column gap-3 ps-1 social-icons">
              {socialLinks.map((social, index) => (
                <a 
                  key={index} 
                  href={social.link} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="social-icon d-flex align-items-center justify-content-center"
                  style={{ 
                    color: social.color,
                    transition: 'transform 0.3s ease',
                    textDecoration: 'none'
                  }}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform = 'scale(1.2)';
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = 'scale(1)';
                  }}
                >
                  <i className={`${social.icon} fs-4`}></i>
                </a>
              ))}
            </div>
          </div>
          <div className="col-lg-5 order-2 order-lg-1 d-flex flex-column justify-content-center hero-main">
            <h2 data-aos="fade-up">{t('hero_title')}</h2>
            <p data-aos="fade-up" data-aos-delay="100">{t('hero_description')}</p>
            <div className="d-flex flex-column flex-md-row" data-aos="fade-up" data-aos-delay="200">
              <Link to="/#services" className="btn-get-started">
                {t('hero_button')} <i className="bi bi-arrow-right me-1"></i>
              </Link>
            </div>
          </div>
          <div className="col-lg-6 order-1 order-lg-2 hero-img" data-aos="zoom-out">
            <img 
              src={Logo} 
              className="img-fluid animated custom-image" 
              alt="Logo" 
              loading="lazy" 
            
  />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
