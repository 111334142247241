import './Home.css';
import Hero from '../../sections/home/hero/Hero';
import About from '../../sections/home/about/About';
import Tech from '../../sections/home/tech/Tech';
import Idee from '../../sections/home/idee/Idee';
import Service from '../../sections/home/service/Service';
import Contact from '../../sections/home/contact/Contact';
import Methode from '../../sections/home/methode/Methode';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const Home = () => {
    const location = useLocation();

    useEffect(() => {
        const handleHashScroll = () => {
            if (location.hash) {
                const element = document.getElementById(location.hash.substring(1));
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                }
            }
        };

        handleHashScroll(); // Scroll on initial load

        // Listen for hash changes
        window.addEventListener('hashchange', handleHashScroll);

        // Clean up event listener
        return () => {
            window.removeEventListener('hashchange', handleHashScroll);
        };
    }, [location]);
    return (
        <>
            <Hero/>
            <About/>
            <Service/>
            <Tech/>
            <Methode/>
            <Idee/>
            <Contact/>            
        </>
    )
}

export default Home;
