import sol1 from '../assets/img/project/solution/sol1.webp';
import sol2 from '../assets/img/project/solution/sol2.webp';
import sol3 from '../assets/img/project/solution/sol3.webp';
import sol4 from '../assets/img/project/solution/sol4.webp';
import sol5 from '../assets/img/project/solution/sol5.webp';
import sol6 from '../assets/img/project/solution/sol6.webp';


import shop1 from '../assets/img/project/ecom/shop1.webp';
import shop2 from '../assets/img/project/ecom/shop2.webp';
import shop3 from '../assets/img/project/ecom/shop3.webp';
import shop4 from '../assets/img/project/ecom/shop4.webp';
import shop5 from '../assets/img/project/ecom/shop5.webp';
import shop6 from '../assets/img/project/ecom/shop6.webp';


import mob1 from '../assets/img/project/mobile/mob1.png';
import mob2 from '../assets/img/project/mobile/mob2.png';
import mob3 from '../assets/img/project/mobile/mob3.png';
import mob4 from '../assets/img/project/mobile/mob4.png';
import mob5 from '../assets/img/project/mobile/mob5.png';
import mob6 from '../assets/img/project/mobile/mob6.png';

import web1 from '../assets/img/project/webSite/web1.webp';
import web2 from '../assets/img/project/webSite/web2.webp';
import web3 from '../assets/img/project/webSite/web3.webp';
import web4 from '../assets/img/project/webSite/web4.webp';
import web5 from '../assets/img/project/webSite/web5.webp';
import web6 from '../assets/img/project/webSite/web6.webp';

import rev1 from '../assets/img/project/renovation/rev1.webp';
import rev2 from '../assets/img/project/renovation/rev2.webp';
import rev3 from '../assets/img/project/renovation/rev3.webp';
import rev4 from '../assets/img/project/renovation/rev4.webp';
import rev5 from '../assets/img/project/renovation/rev5.webp';
import rev6 from '../assets/img/project/renovation/rev6.webp';


import aut1 from '../assets/img/project/automat/aut1.webp';
import aut2 from '../assets/img/project/automat/aut2.webp';
import aut3 from '../assets/img/project/automat/aut3.webp';
import aut4 from '../assets/img/project/automat/aut4.webp';
import aut5 from '../assets/img/project/automat/aut5.webp';
import aut6 from '../assets/img/project/automat/aut6.webp';



import icon4 from '../assets/img/icon/icon4.png';
import icon5 from '../assets/img/icon/icon5.png';
import solution1 from '../assets/img/icon/icon5.png';

export const solutionData = {
    items: [
      {
        image: sol1,
        delay: 100
      },
      {
        image: sol2,
        delay: 400
      },
      {
        image: sol3,
        delay: 500
      },
      {
        image: sol4,
        delay: 500
      },
      {
        image: sol5,
        delay: 500
      },
      {
        image: sol6,
        delay: 500
      }
    ]
  };

export const mobileData = {
    items: [
      {
        image: mob1,
        delay: 100
      },
      {
        image: mob2,
        delay: 400
      },
      {
        image: mob3,
        delay: 500
      },
      {
        image: mob4,
        delay: 400
      },
      {
        image: mob5,
        delay: 500
      },
      {
        image: mob6,
        delay: 500
      },
    ]
  };

export const webSiteData = {
    items: [
      {
        image: web1,
        delay: 100
      },
      {
        image: web2,
        delay: 400
      },
      {
        image: web3,
        delay: 500
      },
      {
        image: web4,
        delay: 400
      },
      {
        image: web5,
        delay: 500
      },
      {
        image: web6,
        delay: 500
      },
    ]
  };
  
export const renovationSiteData = {
    items: [
      {
        image: rev1,
        delay: 100
      },
      {
        image: rev2,
        delay: 400
      },
      {
        image: rev3,
        delay: 500
      },
      {
        image: rev4,
        delay: 400
      },
      {
        image: rev5,
        delay: 500
      },
      {
        image: rev6,
        delay: 500
      },
    ]
  };

export const automatisationData = {
    items: [
      {
        image: aut1,
        delay: 100
      },
      {
        image: aut2,
        delay: 400
      },
      {
        image: aut3,
        delay: 500
      },
      {
        image: aut4,
        delay: 400
      },
      {
        image: aut5,
        delay: 500
      },
      {
        image: aut6,
        delay: 500
      },
    ]
  };

export const eComData = {
    items: [
      {
        image: shop1,
        delay: 100
      },
      {
        image: shop2,
        delay: 400
      },
      {
        image: shop3,
        delay: 500
      },
      {
        image: shop4,
        delay: 400
      },
      {
        image: shop5,
        delay: 500
      },
      {
        image: shop6,
        delay: 500
      },
    ]
  };
  