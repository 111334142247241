import React, { useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import icon1 from '../../../assets/img/icon1.png';
import icon2 from '../../../assets/img/icon2.png';
import icon3 from '../../../assets/img/icon3.png';
import icon4 from '../../../assets/img/icon4.png';

// Register ScrollTrigger plugin
gsap.registerPlugin(ScrollTrigger);

const About = () => {
  const { t, i18n } = useTranslation(); // Get current language direction from i18n
  const sectionRef = useRef(null);
  const featuresRef = useRef([]);
  const titleRef = useRef(null);
  const descriptionRef = useRef(null);
  const companyCardRef = useRef(null);

  const features = [
    {
      icon: icon4,
      title: t('innovative_solutions'),
      description: t('innovative_description'),
      colorClass: 'border-primary',
      iconBg: 'bg-primary-subtle'
    },
    {
      icon: icon2,
      title: t('customer_centric_approach'),
      description: t('customer_centric_description'),
      colorClass: 'border-success',
      iconBg: 'bg-success-subtle'
    },
    {
      icon: icon1,
      title: t('agility_flexibility'),
      description: t('agility_flexibility_description'),
      colorClass: 'border-info',
      iconBg: 'bg-info-subtle'
    },
    {
      icon: icon3,
      title: t('expert_team'),
      description: t('expert_team_description'),
      colorClass: 'border-warning',
      iconBg: 'bg-warning-subtle'
    }
  ];

  useEffect(() => {
    const section = sectionRef.current;
    const title = titleRef.current;
    const description = descriptionRef.current;
    const companyCard = companyCardRef.current;
    const featureElements = featuresRef.current;

    // Section entrance animation
    gsap.fromTo(
      section,
      { opacity: 0, y: 50 },
      { 
        opacity: 1, 
        y: 0, 
        duration: 1, 
        ease: 'power3.out',
        scrollTrigger: {
          trigger: section,
          start: 'top 80%',
        }
      }
    );

    // Title and description animation
    gsap.fromTo(
      [title, description],
      { opacity: 0, y: 30 },
      { 
        opacity: 1, 
        y: 0, 
        duration: 0.8, 
        stagger: 0.2,
        ease: 'power2.out',
        scrollTrigger: {
          trigger: section,
          start: 'top 80%',
        }
      }
    );

    gsap.fromTo(
      companyCard,
      { opacity: 0, x: -50 },
      { 
        opacity: 1, 
        x: 0, 
        duration: 1, 
        ease: 'power3.out',
        scrollTrigger: {
          trigger: companyCard,
          start: 'top 80%',
        }
      }
    );

    // Features animation
    gsap.fromTo(
      featureElements,
      { opacity: 0, scale: 0.8 },
      { 
        opacity: 1, 
        scale: 1, 
        duration: 0.7, 
        stagger: 0.2,
        ease: 'back.out(1.7)',
        scrollTrigger: {
          trigger: section,
          start: 'top 70%',
        }
      }
    );
  }, []);

  // Determine if the language is RTL or LTR
  const isRTL = i18n.dir() === 'rtl';

  return (
    <section 
      id="about"
      ref={sectionRef}
      className={`about position-relative py-5 overflow-hidden ${isRTL ? 'text-end' : ''}`} 
      dir={isRTL ? 'rtl' : 'ltr'}
    >
      <div className="container">
        <div className="text-center mb-5">
          <h2 
            ref={titleRef} 
            className="display-6 fw-bold text-primary mb-3 aos-init"
          >
            {t('about_us')}
          </h2>
        </div>

        <div className="row g-4 align-items-stretch">
          {/* Company Card */}
          <div className={`col-lg-6 ${isRTL ? 'order-lg-2' : ''}`}>
            <div 
              ref={companyCardRef}
              className="card h-100 shadow-lg border-0 rounded-4 p-4"
            >
              <div>
                <h3
                  className="card-title text-primary fs-2 mb-4"
                  style={{ lineHeight: "1.5", fontSize: "2.5rem" }} // Adjust the font size here
                >
                  FoundryByte
                </h3>
                <p
                  className="card-text flex-grow-1"
                  style={{ lineHeight: "2", fontSize: "1.2rem" }} // Adjust the font size here
                >
                  {t('about_description')}
                </p>
                <Link
                  to="#contact"
                  className="btn btn-primary mt-5"
                  style={{ fontSize: "20px", padding: "12px 24px" }} // Adjust font size and padding for the button
                >
                  {t('contact_us')}
                </Link>
              </div>
            </div>
          </div>

          {/* Features Grid */}
          <div className={`col-lg-6 ${isRTL ? 'order-lg-1' : ''}`}>
            <div className="row g-4">
              {features.map((feature, index) => (
                <div key={index} className="col-md-6">
                  <div 
                    ref={el => featuresRef.current[index] = el}
                    className={`card h-100 border ${feature.colorClass} position-relative overflow-hidden`}
                  >
                    <div className="card-body text-center p-4">
                      <div 
                        className={`rounded-circle mb-3 mx-auto d-flex align-items-center justify-content-center ${feature.iconBg}`}
                        style={{ 
                          width: '100px', 
                          height: '100px', 
                          transition: 'transform 0.3s ease' 
                        }}
                      >
                        <img 
                          src={feature.icon} 
                          alt={feature.title}
                          className="img-fluid"
                          style={{ maxWidth: '60px', maxHeight: '60px' }}
                        />
                      </div>
                      <h5 className="card-title mb-3">{feature.title}</h5>
                      <p className="card-text text-muted">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
