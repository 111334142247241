import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en/translation.json';
import translationFR from './locales/fr/translation.json';
import translationAR from './locales/ar/translation.json';
import translationES from './locales/es/translation.json';
import translationIT from './locales/it/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  fr: {
    translation: translationFR,
  },
  ar: {
    translation: translationAR,
  },
  es: {

     translation : translationES 
  },
  it : 
  {
    translation : translationIT
  }
};

i18n
  .use(LanguageDetector) // Add language detection
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: 'en', // Fallback language if the user's language is not found
    interpolation: {
      escapeValue: false, // React already escapes values
    },
  });

export default i18n;
