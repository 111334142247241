import icon1 from '../assets/img/icon/icon1.png';
import icon2 from '../assets/img/icon/icon2.png';
import icon3 from '../assets/img/icon/icon3.png';
import icon4 from '../assets/img/icon/icon4.png';
import icon5 from '../assets/img/icon/icon5.png';
import icon6 from '../assets/img/icon/icon6.png';
import icon7 from '../assets/img/icon/icon7.png';
import icon8 from '../assets/img/icon/icon8.png';
import icon9 from '../assets/img/icon/icon9.png';
import icon10 from '../assets/img/icon/icon10.png';
import icon11 from '../assets/img/icon/icon11.png';
import icon12 from '../assets/img/icon/icon12.png';



const iconData = [
    {
        icon: icon1,
        title: "Django",
        delay: 100
    },
    {
        icon: icon2,
        title: "React",
        delay: 200
    },
    {
        icon: icon3,
        title: "Spring Boot",
        delay: 300
    },
    {
        icon: icon4,
        title: "Angular",
        delay: 400
    },
    {
        icon: icon5,
        title: "JavaScript",
        delay: 500
    },
    {
        icon: icon6,
        title: "Nginx",
        delay: 600
    },
    {
        icon: icon7,
        title: "Kafka Apache",
        delay: 700
    },
    {
        icon: icon8,
        title: "Docker",
        delay: 800
    },
    {
        icon: icon9,
        title: "TypeScript",
        delay: 900
    },
    {
        icon: icon10,
        title: "Symfony",
        delay: 1000
    },
    {
        icon: icon11,
        title: "Redis",
        delay: 1100
    },
    {
        icon: icon12,
        title: "Bootstrap",
        delay: 1200
    }
  ];
  
  export default iconData;
  