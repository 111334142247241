import './Project.css';
import Portfolio from '../../sections/projet/portfolio/Portfolio';

const Project = () => {
    return (
        <>
            <Portfolio/>            
        </>
    )
}

export default Project;
