import './Methode.css';
import { useTranslation } from 'react-i18next';
import icon1 from '../../../assets/img/meth1.png';
import icon2 from '../../../assets/img/meth2.png';
import icon3 from '../../../assets/img/meth3.png';

const Methode = () => {
    const { t, i18n } = useTranslation(); // useTranslation hook to get the translation function and language

    return (
        <section id="methodes" className={`what-we-do section ${i18n.language === 'ar' ? 'rtl' : 'ltr'}`}>
            <div className="container section-title" data-aos="fade-up">
                <h2>{t('Our Method')}</h2>
                <p>{t('collaborative_approach_description')}</p> {/* Description with translation */}
            </div>

            <div className="container">
                <div className="row gy-4">
                    <div className="col-lg-4" data-aos="fade-up" data-aos-delay="100">
                        <div className="why-box">
                            <h3>{t('Why Choose Our Approach?')}</h3>  {/* Title with translation */}
                            <p>{t('why_choose_approach_description')}</p> {/* Content with translation */}
                        </div>
                    </div>

                    <div className="col-lg-8 d-flex align-items-stretch">
                        <div className="row gy-4" data-aos="fade-up" data-aos-delay="200">
                            <div className="col-xl-4">
                                <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                                    <div className="icon">
                                        <img loading="lazy" src={icon1} alt="Innovative Solutions" style={{ width: '60px', height: '60px' }} />
                                    </div>
                                    <h4>{t('Client Involvement')}</h4> {/* Title with translation */}
                                    <p>{t('client_involvement_description')}</p> {/* Content with translation */}
                                </div>
                            </div>

                            <div className="col-xl-4" data-aos="fade-up" data-aos-delay="300">
                                <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                                    <div className="icon">
                                        <img loading="lazy" src={icon2} alt="Tailored Solutions" style={{ width: '60px', height: '60px' }} />
                                    </div>
                                    <h4>{t('Tailored Solutions')}</h4> {/* Title with translation */}
                                    <p>{t('tailored_solutions_description')}</p> {/* Content with translation */}
                                </div>
                            </div>

                            <div className="col-xl-4" data-aos="fade-up" data-aos-delay="400">
                                <div className="icon-box d-flex flex-column justify-content-center align-items-center">
                                    <div className="icon">
                                        <img loading="lazy" src={icon3} alt="Timely Delivery" style={{ width: '60px', height: '60px' }} />
                                    </div>
                                    <h4>{t('Timely Delivery')}</h4> {/* Title with translation */}
                                    <p>{t('timely_delivery_description')}</p> {/* Content with translation */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Methode;
