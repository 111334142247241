import React, { useState, useRef, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';  // Import translation hook
import { solutionData, mobileData, webSiteData, automatisationData, eComData, renovationSiteData } from '../../../data/projetData';

const Portfolio = () => {
    const { status } = useParams();
    const { t } = useTranslation(); // Get the translation function
    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [scrollValues, setScrollValues] = useState({});
  
    const imageRefs = useRef([]);
    const containerRefs = useRef([]);
  
    const projectData = getDataByStatus(status); 

    useEffect(() => {
      if (projectData.items.length > 0) {
        projectData.items.forEach((_, index) => {
          const img = imageRefs.current[index];
          const container = containerRefs.current[index];
          if (img && container) {
            const imageHeight = img.offsetHeight;
            const containerHeight = container.offsetHeight;
            const scrollDistance = imageHeight - containerHeight;
    
            // Only update scrollValues if the new value differs
            if (scrollValues[index] !== scrollDistance) {
              setScrollValues((prev) => ({
                ...prev,
                [index]: scrollDistance > 0 ? scrollDistance : 0,
              }));
            }
          }
        });
      }
    }, [projectData.items, status]);
    
  
    useEffect(() => {
      const resizeObserver = new ResizeObserver(() => {
        projectData.items.forEach((_, index) => {
          const img = imageRefs.current[index];
          const container = containerRefs.current[index];
          if (img && container) {
            const imageHeight = img.offsetHeight;
            const containerHeight = container.offsetHeight;
            const scrollDistance = imageHeight - containerHeight;
    
            // Only update if the value has changed
            if (scrollValues[index] !== scrollDistance) {
              setScrollValues((prev) => ({
                ...prev,
                [index]: scrollDistance > 0 ? scrollDistance : 0,
              }));
            }
          }
        });
      });
    
      imageRefs.current.forEach((img, index) => {
        const container = containerRefs.current[index];
        if (img && container) {
          resizeObserver.observe(img);
          resizeObserver.observe(container);
        }
      });
    
      return () => {
        resizeObserver.disconnect();
      };
    }, [projectData.items, scrollValues]); 
    
  
    const handleMouseEnter = (index) => {
      setHoveredIndex(index);
    };
  
    const handleMouseLeave = () => {
      setHoveredIndex(null);
    };
  
    return (
      <section id="portfolio" className="portfolio section">
        <div className="container section-title" data-aos="fade-up">
          <h2>{t(projectData.titleKey)}</h2> {/* Use translated title */}
          <p>{t(projectData.descriptionKey)}</p> {/* Use translated description */}
        </div>
  
        <div className="container">
          <div className="row gy-5" data-aos="fade-up" data-aos-delay="200">
            {projectData.items.map((project, index) => (
              <div key={index} className="col-lg-4 col-md-6">
                <div
                  className="portfolio-content d-flex align-items-center justify-content-center position-relative"
                  onMouseEnter={() => handleMouseEnter(index)}
                  onMouseLeave={handleMouseLeave}
                  ref={(el) => (containerRefs.current[index] = el)}
                  style={{
                    overflow: 'hidden',
                    height: '350px',
                    width: '100%',
                    position: 'relative',
                  }}
                >
                  <img
                    src={project.image}
                    alt={`Project ${index + 1}`}  // Alt text for accessibility
                    className="position-absolute w-100 h-auto"
                    loading="lazy" 
                    ref={(el) => (imageRefs.current[index] = el)}
                    style={{
                      top: 0,
                      transform: hoveredIndex === index && scrollValues[index]
                        ? `translateY(-${scrollValues[index]}px)`
                        : 'translateY(0)',
                      transition: 'transform 4s ease-in-out',
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
};

// Helper to get data by status, now using translation keys
const getDataByStatus = (status) => {
    switch (status) {
        case '1':
            return { titleKey: 'solution_entreprise_title', descriptionKey: 'solution_entreprise', ...solutionData };
        case '2':
            return { titleKey: 'application_mobile_title', descriptionKey: 'application_mobile', ...mobileData };
        case '3':
            return { titleKey: 'creation_website_title', descriptionKey: 'creation_website', ...webSiteData };
        case '5':
            return { titleKey: 'automatisation_title', descriptionKey: 'automatisation', ...automatisationData };
        case '6':
            return { titleKey: 'renovation_website_title', descriptionKey: 'renovation_website', ...renovationSiteData };
        case '7':
            return { titleKey: 'ecommerce_title', descriptionKey: 'ecommerce', ...eComData };
        default:
            return { titleKey: "unknown_title", descriptionKey: "unknown_description", items: [] };
    }
};

export default Portfolio;
