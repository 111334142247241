import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

// Flag SVG Components
const Flags = {
  US: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="24" height="18">
      <g fillRule="evenodd">
        <g strokeWidth="1pt">
          <path fill="#bd3d44" d="M0 0h972.8v39.4H0zm0 78.8h972.8v39.4H0zm0 78.7h972.8V197H0zm0 78.8h972.8v39.4H0zm0 78.8h972.8v39.4H0zm0 78.7h972.8v39.4H0zm0 78.8h972.8V512H0z" transform="scale(.9375)"/>
          <path fill="#fff" d="M0 39.4h972.8v39.4H0zm0 78.8h972.8v39.3H0zm0 78.7h972.8v39.4H0zm0 78.8h972.8v39.4H0zm0 78.8h972.8v39.4H0zm0 78.7h972.8v39.4H0z" transform="scale(.9375)"/>
        </g>
        <path fill="#192f6a" d="M0 0h389.1v275.7H0z" transform="scale(.9375)"/>
        <path fill="#fff" d="M32.4 11.8L36 22.2h11.7l-9.5 6.9 3.6 10.4-9.5-6.9-9.6 6.9 3.6-10.4-9.5-6.9H28zm64.9 0l3.6 10.4h11.7l-9.6 6.9 3.6 10.4-9.5-6.9-9.5 6.9 3.6-10.4-9.6-6.9h11.7zm64.8 0l3.6 10.4h11.7L151 28.5l3.6 10.4-9.5-6.9-9.6 6.9 3.6-10.4-9.5-6.9h11.7zm64.9 0l3.6 10.4h11.7l-9.5 6.9 3.6 10.4-9.6-6.9-9.5 6.9 3.6-10.4-9.6-6.9h11.8zm64.8 0l3.6 10.4h11.7l-9.6 6.9 3.6 10.4-9.5-6.9-9.6 6.9 3.6-10.4-9.5-6.9h11.7zm64.9 0l3.6 10.4h11.7l-9.5 6.9 3.6 10.4-9.6-6.9-9.5 6.9 3.6-10.4-9.6-6.9h11.8zM64.9 39.4l3.5 10.4h11.8l-9.6 6.9 3.6 10.4-9.5-6.9-9.5 6.9 3.6-10.4-9.6-6.9h11.7zm64.8 0l3.6 10.4h11.7l-9.5 6.9 3.6 10.4-9.6-6.9-9.5 6.9 3.6-10.4-9.6-6.9h11.8zm64.9 0l3.6 10.4h11.7l-9.6 6.9 3.6 10.4-9.5-6.9-9.5 6.9 3.6-10.4-9.5-6.9h11.7zm64.8 0l3.6 10.4h11.7l-9.5 6.9 3.6 10.4-9.6-6.9-9.5 6.9 3.6-10.4-9.6-6.9h11.8zm64.9 0l3.6 10.4h11.7l-9.6 6.9 3.6 10.4-9.5-6.9-9.5 6.9 3.6-10.4-9.5-6.9h11.7zm-226.2 27.7l3.6 10.4h11.7l-9.6 6.9 3.6 10.4-9.5-6.9-9.5 6.9 3.6-10.4-9.6-6.9h11.7zm64.8 0l3.6 10.4h11.7l-9.5 6.9 3.6 10.4-9.6-6.9-9.5 6.9 3.6-10.4-9.6-6.9h11.8zm64.9 0l3.6 10.4h11.7l-9.6 6.9 3.6 10.4-9.5-6.9-9.5 6.9 3.6-10.4-9.5-6.9h11.7zm64.8 0l3.6 10.4h11.7l-9.5 6.9 3.6 10.4-9.6-6.9-9.5 6.9 3.6-10.4-9.6-6.9h11.8zm64.9 0l3.6 10.4h11.7l-9.6 6.9 3.6 10.4-9.5-6.9-9.5 6.9 3.6-10.4-9.5-6.9h11.7z" transform="scale(.9375)"/>
      </g>
    </svg>
  ),
  FR: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="24" height="18">
      <g fillRule="evenodd" strokeWidth="1pt">
        <path fill="#fff" d="M0 0h640v480H0z"/>
        <path fill="#002395" d="M0 0h213.3v480H0z"/>
        <path fill="#ed2939" d="M426.7 0H640v480H426.7z"/>
      </g>
    </svg>
  ),
  ES: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="24" height="18">
      <rect width="640" height="480" fill="#aa151b" />
      <rect y="120" width="640" height="240" fill="#f1bf00" />
      <rect y="0" width="640" height="60" fill="#aa151b" />
      <rect y="420" width="640" height="60" fill="#aa151b" />
      <g transform="scale(0.5)">
        <g transform="translate(160, 180)">
          <circle cx="80" cy="80" r="80" fill="#f1bf00" />
          <text
            x="50%"
            y="50%"
            dominantBaseline="middle"
            textAnchor="middle"
            fontSize="90"
            fontFamily="Arial, sans-serif"
            fill="#aa151b"
          >
            🇪🇸
          </text>
        </g>
      </g>
    </svg>
  )
,  
  IT: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="24" height="18">
      <g fillRule="evenodd" strokeWidth="1pt">
        <path fill="#fff" d="M0 0h640v480H0z"/>
        <path fill="#009246" d="M0 0h213.3v480H0z"/>
        <path fill="#ce2b37" d="M426.7 0H640v480H426.7z"/>
      </g>
    </svg>
  ),
  AR: () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 480" width="24" height="18">
      <path fill="#006C35" d="M0 0h640v480H0z"/>
      <g fill="#fff">
        <text
          x="50%"
          y="42%"
          dominantBaseline="middle"
          textAnchor="middle"
          fontSize="50"
          fontFamily="'Arial', sans-serif"
          direction="rtl"
        >
      لاه
        </text>
        <path d="M180 330h280v20H180z"/>
        <path d="M160 340h30l-10 15h-10l-10-15z"/>
      </g>
    </svg>
  )
  
};

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('language') || 'en');
  const selectRef = useRef(null);

  // Language configuration with flags and full names
  const languages = [
    { code: 'en', name: 'English', FlagComponent: Flags.US },
    { code: 'fr', name: 'Français', FlagComponent: Flags.FR },
    { code: 'es', name: 'Español', FlagComponent: Flags.ES },
    { code: 'it', name: 'Italiano', FlagComponent: Flags.IT },
    { code: 'ar', name: 'العربية', FlagComponent: Flags.AR }
  ];

  const handleLanguageChange = (lng) => {
    if (selectedLanguage !== lng) {
      i18n.changeLanguage(lng);
      localStorage.setItem('language', lng);
      setSelectedLanguage(lng);
      setIsOpen(false);
    }
  };

  // Close dropdown when clicking outside
  const handleClickOutside = (event) => {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  // Add and remove event listener
  React.useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Find the currently selected language
  const currentLanguage = languages.find(lang => lang.code === selectedLanguage) || languages[0];

  return (
    <div 
      ref={selectRef}
      className="language-switcher"
      style={{
        position: 'relative',
        width: '220px',
        fontFamily: 'Inter, Arial, sans-serif',
        zIndex: 1000
      }}
    >
      {/* Current Language Display */}
      <div 
        onClick={() => setIsOpen(!isOpen)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          padding: '10px 15px',
          border: '1px solid #e0e0e0',
          borderRadius: '12px',
          cursor: 'pointer',
          backgroundColor: 'white',
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
          transition: 'all 0.3s ease',
          color: '#333'
        }}
        className="language-selector"
      >
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ 
            marginRight: '10px', 
            display: 'flex', 
            alignItems: 'center' 
          }}>
            <currentLanguage.FlagComponent />
          </span>
          <span style={{ 
            fontWeight: 500,
            fontSize: '0.9rem'
          }}>
            {currentLanguage.name}
          </span>
        </span>
        <span 
          style={{ 
            transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
            transition: 'transform 0.3s ease',
            color: '#666'
          }}
        >
          ▼
        </span>
      </div>

      {/* Dropdown */}
      {isOpen && (
        <div 
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            width: '100%',
            backgroundColor: 'white',
            border: '1px solid #e0e0e0',
            borderRadius: '12px',
            marginTop: '5px',
            boxShadow: '0 8px 15px rgba(0,0,0,0.15)',
            zIndex: 1000,
            maxHeight: '300px',
            overflowY: 'auto',
            padding: '5px 0'
          }}
          className="language-dropdown"
        >
          {languages.map((lang) => (
            <div
              key={lang.code}
              onClick={() => handleLanguageChange(lang.code)}
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '10px 15px',
                cursor: 'pointer',
                backgroundColor: selectedLanguage === lang.code ? '#f0f0f0' : 'white',
                transition: 'background-color 0.3s ease, transform 0.1s ease',
                borderRadius: '8px',
                margin: '0 5px'
              }}
              className="language-option"
              onMouseEnter={(e) => {
                e.currentTarget.style.backgroundColor = '#f5f5f5';
                e.currentTarget.style.transform = 'scale(1.02)';
              }}
              onMouseLeave={(e) => {
                e.currentTarget.style.backgroundColor = selectedLanguage === lang.code ? '#f0f0f0' : 'white';
                e.currentTarget.style.transform = 'scale(1)';
              }}
            >
              <span style={{ 
                marginRight: '10px', 
                display: 'flex', 
                alignItems: 'center' 
              }}>
                <lang.FlagComponent />
              </span>
              <span style={{ 
                fontWeight: selectedLanguage === lang.code ? 600 : 400,
                color: selectedLanguage === lang.code ? '#007bff' : '#333'
              }}>
                {lang.name}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LanguageSwitcher;