import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Service.css';
import serviceData from '../../../data/serviceData';
import { useTranslation } from 'react-i18next';

const Service = () => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const isRTL = i18n.dir() === 'rtl'; // Checks the current language direction.

  const handleClick = (status) => {
    navigate(`/projet/${status}`);
  };

  return (
    <section id="services" className={`services section ${isRTL ? 'rtl' : ''}`}>
      <div className="container section-title" data-aos="fade-up">
        <h2>{t('services_title')}</h2>
      </div>

      <div className="container">
        <div className="row gy-4">
          {serviceData.map((service, index) => (
            <div key={index} className="col-xl-4 col-md-6 d-flex" data-aos="fade-up" data-aos-delay={service.delay}>
              <div className="service-item position-relative" onClick={() => handleClick(service.status)}>
                <img loading="lazy" src={service.icon} alt={t(service.titleKey)} style={{ width: '60px', height: '60px' }} />
                <h4 className="stretched-link">{t(service.titleKey)}</h4>
                <p>{t(service.descriptionKey)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Service;
