import React from 'react';
import './Footer.css';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import { MapPin, Phone, Mail } from 'lucide-react';

const Footer = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  // Function to navigate based on the status
  const handleNavigation = (event, status) => {
    event.preventDefault();
    navigate(`/projet/${status}`);
  };
  // Function to scroll to section
  const handleScrollToSection = (event, sectionId) => {
    event.preventDefault();
    if (location.pathname === '/') {
      const section = document.getElementById(sectionId);
      if (section) {
        section.scrollIntoView({ behavior: 'smooth' });
      }
    } else {
      navigate(`/#${sectionId}`);
    }
  };

  return (
    <footer className="footer">
      <div className="container">
        <div className="footer-top row">
          <div className="col-md-4 footer-about">
            <span className="brand-name">FoundryByte <strong>.</strong></span>
            <div className="footer-contact mt-3">
              <div className="contact-item location d-flex align-items-center mb-3">
                <MapPin className="mr-2 contact-icon" size={24} />
                <div>
                  <strong className="city-label">Location</strong>
                  <p className="city mb-0">Rabat, Agdal</p>
                </div>
              </div>
              
              <div className="contact-item phone d-flex align-items-center mb-3">
                <Phone className="mr-2 contact-icon" size={24} />
                <div>
                  <strong className="phone-label">Phone</strong>
                  <div className="phone-numbers">
                    <p className="mb-0">+212 667 52 31 71</p>
                    <p className="mb-0">+212 624 49 73 76</p>
                  </div>
                </div>
              </div>
              
              <div className="contact-item email d-flex align-items-center">
                <Mail className="mr-2 contact-icon" size={24} />
                <div>
                  <strong className="email-label">Email</strong>
                  <p className="email mb-0">foundrybyte@gmail.com</p>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-4 footer-links">
            <h4>{t('Footer_links')}</h4>
            <ul>
              <li><a href="#" onClick={(e) => handleScrollToSection(e, 'hero')}>{t('home')}</a></li>
              <li><a href="#" onClick={(e) => handleScrollToSection(e, 'services')}>{t('services_header')}</a></li>
              <li><a href="#" onClick={(e) => handleScrollToSection(e, 'about')}>{t('about_header')}</a></li>
              <li><a href="#" onClick={(e) => handleScrollToSection(e, 'features')}>{t('technologies_header')}</a></li>
            </ul>
          </div>

          <div className="col-md-4 footer-links">
            <h4>{t('Footer_services')}</h4>
            <ul>
              <li><a href="#" onClick={(e) => handleNavigation(e, 1)}>{t('application_mobile_title')}</a></li>
              <li><a href="#" onClick={(e) => handleNavigation(e, 3)}>{t('creation_website_title')}</a></li>
              <li><a href="#" onClick={(e) => handleNavigation(e, 2)}>{t('renovation_website_title')}</a></li>
              <li><a href="#" onClick={(e) => handleNavigation(e, 5)}>{t('automatisation_title')}</a></li>
            </ul>
          </div>
        </div>

        <div className="copyright-container">
          <p className="copyright">
            © {new Date().getFullYear()} FoundryByte. {t('All rights reserved')}
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;