import React from 'react';
import './Idee.css';
import { useTranslation } from 'react-i18next'; 
import { Link } from 'react-router-dom';

const Idee = () => {
  const { t } = useTranslation(); 

  return (
    <section id="call-to-action" className="call-to-action section accent-background">
        <div className="container">
            <div className="row justify-content-center" data-aos="zoom-in" data-aos-delay="100">
                <div className="col-xl-10">
                    <div className="text-center">
                      <h3>{t('cta_title')}</h3> 
                      <p>{t('cta_description')}</p> 
                      <Link to="projet/1" className="cta-btn service-btn" >{t('services_title')}</Link> 
                      <a href="#contact" className="cta-btn" >{t('cta_button')}</a> 
                    </div>
                </div>
            </div>
        </div>
    </section>
  );
};

export default Idee;